
import SatIcon from "@/components/SatIcon.vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import { defineComponent, PropType, reactive, ref, toRefs, watch } from "vue";
import ProvinceSelector from "./selector/ProvinceSelector.vue";
import CountrySelector from "./selector/CountrySelector.vue";
import NationalitySelector from "./selector/NationalitySelector.vue";
import PositionSelector from "./selector/PositionSelector.vue";
import DivisionSelector from "./selector/DivisionSelector.vue";
import YearsSelector from "./selector/YearsSelector.vue";
import { AthleteQueryDto } from "@/_modules/types";
import CompetitionSelector from "./selector/CompetitionSelector.vue";
import StadiumSelector from "./selector/StadiumSelector.vue";
import Slider from "@vueform/slider";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface LabelValue {
  label: {
    en: string;
    th: string;
  };
  value: string;
  dot?: string;
}
const injuryTagsTemplate = [
  { label: { th: "ไม่มีอาการบาดเจ็บ", en: "No Injury" }, value: "" },
  { label: { th: "เล็กน้อย", en: "Minor" }, value: "MINOR", dot: "bg-primary" },
  {
    label: { th: "ปานกลาง", en: "Moderate" },
    value: "MODERATE",
    dot: "bg-warning",
  },
  {
    label: { th: "หนัก", en: "Seriously" },
    value: "SERIOUSLY",
    dot: "bg-danger",
  },
];
export default defineComponent({
  name: "SatSearchInput",
  props: {
    placeHolder: {
      type: String,
      required: false,
      default: "ค้นหานักกีฬา ชื่อจริง นามสกุล",
    },
    clearInput: {
      type: Boolean,
    },
    advanceSearch: {
      type: String as PropType<
        | "athlete"
        | "stadium"
        | "staff"
        | "team"
        | "team-tab3"
        | "team-tab4"
        | "team-competition"
      >,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
  },
  components: {
    SatIcon,
    DeleteOutlined,
    ProvinceSelector,
    NationalitySelector,
    PositionSelector,
    CountrySelector,
    DivisionSelector,
    YearsSelector,
    CompetitionSelector,
    StadiumSelector,
    Slider,
  },
  emits: ["search"],
  setup(props, { emit }) {
    const { t, locale } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    let timeOut: number;
    const submitAdvanceSearch = ref<boolean>(false);
    const visibleModal = ref<boolean>(false);
    const visibleAgeRangeTooltip = ref<boolean>(false);
    const perfMarks = ref<Record<number, string>>(
      Object.fromEntries(
        [...Array(11).keys()].map((idx) => [idx * 10, `${idx * 10}`])
      )
    );
    const capacityMarks = ref<Record<number, string>>(
      Object.fromEntries(
        [...Array(7).keys()].map((idx) => [
          (idx + 1) * 10000,
          Number((idx + 1) * 10000).toLocaleString(),
        ])
      )
    );

    const state = reactive({
      nationalityStatus: "",
      positionStatus: null as any,
      departmentStatus: null as any,
      statusNationality: "",
      gender: "",
      ageRange: [15, 35] as number[],
      injuryTags: [...injuryTagsTemplate] as LabelValue[],
      selectedTags: [...injuryTagsTemplate] as LabelValue[],
      performancePercent: 50,
      stadiumCapacityRange: [10000, 20000] as number[],
      province: null as any,
      nationality: null as any,
      injury: "",
      name: "",
      year: new Date().getFullYear(),
      competition: null,
      stadium: null,
      countries: null as any,
      resetStatus: false as Boolean,
    });
    const reset = () => {
      state.resetStatus = true;
      state.nationalityStatus = "all";
      state.gender = "";
      state.ageRange = [15, 35];
      state.statusNationality = "";
      state.positionStatus = null;
      state.departmentStatus = null;
      state.selectedTags = [...injuryTagsTemplate];
      submitAdvanceSearch.value = false;
      state.name = "";
      state.performancePercent = 50;
      state.stadiumCapacityRange = [10000, 20000] || null;
      state.province = null;
      state.nationality = null;
      state.injury = "";
      state.year = new Date().getFullYear();
      state.competition = null;
      state.stadium = null;
      // emit("search", null);
      state.countries = null;
      // visibleModal.value = false;
    };
    const onSearch = (value: string) => {
      submitAdvanceSearch.value = false;
      state.name = value;
      let q = {};
      switch (props?.advanceSearch) {
        case "athlete":
          q = {
            // team_national_status: state.nationalityStatus,
            // minAge: state.ageRange[0],
            // maxAge: state.ageRange[1],
            // gender: state.gender,
            // global_config_positions: state.positionStatus,
            // nationality: state.nationality,
            // provinces: state.province,
            // account_injury_level: state.injury,
            // global_config_physical_fitness_percentage: state.performancePercent,
            name: value,
            page: 1,
            size: 5,
            type: "search", // search, filter
          };
          // } as AthleteQueryDto;
          emit("search", q);
          return;
        case "team-tab3":
          q = {
            name: value,
            isSearch: true,
            nationality: state.nationality,
            global_config_positions: state.positionStatus,
            global_config_department: state.departmentStatus,
          };
          emit("search", q);
          return;
        case "team-competition":
          q = {
            setting_sub_competition_name: value,
            page: 1,
            size: 8,
            setting_competitions: state.competition,
            setting_stadiums: state.stadium,
            setting_sub_competition_year: state.year,
          };
          emit("search", q);
          return;
        case "stadium":
          q = {
            page: 1,
            size: 10,
            name: value,
          };
          emit("search", q);
          return;
        case "team":
          q = {
            name: state.name,
            type: "search",
          };
          emit("search", q);
          return;
        default:
          break;
      }
      if (!value) {
        emit("search", null);
        return;
      }
      emit("search", { name: value || "" });
    };
    const toggleModal = () => {
      visibleModal.value = !visibleModal.value;
      if (timeOut) {
        clearTimeout(timeOut);
      }
      timeOut = setTimeout(() => {
        visibleAgeRangeTooltip.value = !visibleAgeRangeTooltip.value;
      }, 300);
    };
    const handleSearch = (e: unknown) => {
      let q = {};
      switch (props.advanceSearch) {
        case "athlete":
          q = {
            team_national_status: state.statusNationality,
            minAge: state.ageRange[0],
            maxAge: state.ageRange[1],
            gender: state.gender,
            global_config_positions: state.positionStatus,
            nationality: state.nationality,
            provinces: state.province,
            account_injury_level: state.injury,
            global_config_physical_fitness_percentage: state.performancePercent,
            name: state.name,
            type: "filter", // search, filter
          };
          // } as AthleteQueryDto;
          break;
        case "staff":
          q = {
            minAge: state.ageRange[0],
            maxAge: state.ageRange[1],
            gender: state.gender,
            global_config_positions: state.positionStatus,
            nationality: state.nationality,
            global_config_departments: state.departmentStatus,
          };
          break;
        case "team":
          q = {
            minAge: state.ageRange[0],
            maxAge: state.ageRange[1],
            position: state.positionStatus,
            national: state.nationalityStatus,
            department: state.departmentStatus,
            name: state.name,
          };
          break;
        case "team-tab3":
          q = {
            minAge: state.ageRange[0],
            maxAge: state.ageRange[1],
            global_config_department: state.departmentStatus,
            name: state.name,
            global_config_positions: state.positionStatus,
            nationality: state.nationality,
          };
          break;
        case "team-competition":
          q = {
            name: state.name,
            page: 1,
            size: 8,
            setting_competitions: state.competition,
            setting_stadiums: state.stadium,
            setting_sub_competition_year: state.year,
            isFilter: true,
          };
          break;
        case "stadium":
          q = {
            page: 1,
            size: 10,
          };

          if (
            (state.stadiumCapacityRange[0] || state.stadiumCapacityRange[1]) &&
            !state.resetStatus
          ) {
            q = {
              ...q,
              setting_stadium_capacity_min: state.stadiumCapacityRange[0],
              setting_stadium_capacity_max: state.stadiumCapacityRange[1],
            };
          }

          if (+state.countries !== 0) {
            q = {
              ...q,
              countries: +state.countries,
            };
          }

          if (+state.province !== 0) {
            q = {
              ...q,
              provinces: +state.province,
            };
          }
          // q = {
          //   page: 1,
          //   size: 10,
          //   setting_stadium_capacity_min: state.stadiumCapacityRange[0],
          //   setting_stadium_capacity_max: state.stadiumCapacityRange[1],
          //   countries: +state.countries,
          //   provinces: +state.province,
          // };

          break;
        default:
          break;
      }
      submitAdvanceSearch.value = true;
      emit("search", q);
      visibleModal.value = false;
    };

    const handleTagChange = (tag: LabelValue, checked: boolean) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t.value !== tag.value);
      state.selectedTags = nextSelectedTags;
    };
    const handleChangeInjury = (event: any) => {
      state.injury = event.target.value;
    };
    const handleProvinceChange = (selected: string) => {
      state.province = selected;
    };
    const handlePositionChange = (selected: string) => {
      state.positionStatus = selected;
    };
    const handleYearChange = (selected: string) => {
      state.year = +selected;
    };
    const handleNationalChange = (selected: string) => {
      state.nationality = selected;
    };
    const handleCountryChange = (selected: string) => {
      state.countries = selected;
    };
    const handleDivisionChange = (selected: string) => {
      state.departmentStatus = selected;
    };
    const handleGenderChange = (select: any) => {
      state.gender = select.target.value;
    };
    const onChangeName = (event: any) => {
      state.name = event.target.value;
    };
    const onChangeCompetition = (selected: any) => {
      state.competition = selected;
    };
    const onChangeStadium = (selected: any) => {
      state.stadium = selected;
    };
    const { clearInput } = toRefs(props);
    watch(clearInput, () => {
      if (clearInput.value === false) {
        state.name = "";
      }
    });
    return {
      t,
      store,
      visibleModal,
      visibleAgeRangeTooltip,
      perfMarks,
      capacityMarks,
      toggleModal,
      onSearch,
      handleSearch,
      ...toRefs(state),
      handleChangeInjury,
      handleNationalChange,
      handleProvinceChange,
      handleCountryChange,
      handleDivisionChange,
      handlePositionChange,
      handleGenderChange,
      reset,
      submitAdvanceSearch,
      onChangeName,
      handleYearChange,
      onChangeCompetition,
      onChangeStadium,
    };
  },
});
